import React, { useState } from 'react'
import './App.css';
import Header from './components/header/Header';
import Banner from './components/banner/Banner';
import About from './components/about/About';
import Education from './components/education/Education';
import Skill from './components/skills/Skills';
import Experience from './components/experience/Experience';
import Demo from './components/demo/Demo';
import Update from './components/update/Update';
import Profile from './components/profile/Profile';
import 'bootstrap/dist/css/bootstrap.min.css';
import Social from './components/social/Social';
import Slides from './components/slides/Slides';
import Footer from './components/footer/Footer';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <main className={isMenuOpen ? "no-overlay" : ""}>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Banner />
      <About />
      <Update />
      <Skill />
      <Education />
      <Experience />
      <Profile />
      <Demo />
      <Slides />
      <Social />
      <Footer/>
    </main>
  );
}

export default App;

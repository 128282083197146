import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import "./Experience.css";

library.add(fas, faCircle)

export default function Experience() {
    return (

        <section id="experience" className="experience">
            <div className="section-heading text-center">
                <h2 style={{ color: '#3c096c' }}>experience</h2>
            </div>
            <div className="container">
                <div className="experience-content">
                    <div className="main-timeline">
                        <ul>
                            <li className="datalist">
                                <div>
                                    <div className="single-timeline-box fix" >
                                        <div className="timeline-box">
                                            <div className="experience-time text-right">
                                                <h2>2021 - Present</h2>
                                                <h3>Solution Architect</h3>
                                            </div>
                                        </div>
                                        <div className="timeline-box experiencedata">
                                            <div className="timeline">
                                                <div className="timeline-content">
                                                    <h4 className="title">
                                                        <span><FontAwesomeIcon icon={faCircle} /></span>
                                                        Kontent.ai
                                                    </h4>
                                                    <h5>Remote, USA</h5>
                                                    <ul className="description">
                                                        <li style={{ listStyleType: 'circle' }}> Deliver business experience services to customer</li>
                                                        <li style={{ listStyleType: 'circle' }}> Delivering feature demo of the product to the customer</li>
                                                        <li style={{ listStyleType: 'circle' }}> Performing best practice audit for product usage</li>
                                                        <li style={{ listStyleType: 'circle' }}> Bring home - customer feedback</li>
                                                        <li style={{ listStyleType: 'circle' }}> Draft customer feedback for product and enginerring team</li>
                                                        <li style={{ listStyleType: 'circle' }}> Research best practice for customers for feature integration using Kontent.ai</li>
                                                        <li style={{ listStyleType: 'circle' }}> Act as evangelist for content-first approach and headless CMS</li>
                                                        <li style={{ listStyleType: 'circle' }}> Set up Demos, hands-on session with customer to solve their problems</li>
                                                        <li style={{ listStyleType: 'circle' }}> Collaborate with CSM, Pre-sale and other department for ensuring consulting team outcomes</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2020 - 2021</h2>
                                            <h3>Senior Cloud Architect</h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    Clearmotion
                                                </h4>
                                                <h5>Remote, USA</h5>
                                                <ul className="description">
                                                    <li style={{ listStyleType: 'circle' }}>Delivered the overall initial technical design for the product after performing application portfolio analysis</li>
                                                    <li style={{ listStyleType: 'circle' }}>Setup team exercise and involve stake holders to identify the data, security and deployment requirement for the product</li>
                                                    <li style={{ listStyleType: 'circle' }}>Setup a comparison matrix for database comparison for the product</li>
                                                    <li style={{ listStyleType: 'circle' }}>Outlining the Non-functional requirements for cloud solution</li>
                                                    <li style={{ listStyleType: 'circle' }}>Instrumental in cloud budgeting with Director</li>
                                                    <li style={{ listStyleType: 'circle' }}>Developed a POC, for a parallel data processing solution on Azure platform using AKS to deliver data for the data science team up to 30 times faster</li>
                                                    <li style={{ listStyleType: 'circle' }}>Setup POC advance monitoring dashboard using azure insight and log analytics for application developers and management for daily insights.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2019 - 2020</h2>
                                            <h3> Senior Backend Architect</h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    Wrethink
                                                </h4>
                                                <h5>San Diego, USA</h5>
                                                <ul className="description">
                                                    <li style={{ listStyleType: 'circle' }}>In first 90-day, I successfully delivered the AI integrated solution which help “Rosy” to start interaction with our customers along with hiring and managing cost effective remote team of engineers and automation QA. </li>
                                                    <li style={{ listStyleType: 'circle' }}>Drive API/backend architectural decisions to deliver ambitious aspiration of product by working closely with CTO, product owner and lead engineers. </li>
                                                    <li style={{ listStyleType: 'circle' }}>Working with VP of engineering and contributing along with him in Hiring engineering staff (Sr. QA, Sr. Cloud engineer and other team members as we scale), Estimating cost, JIRA Documentation for backend components, third party vendor selection for ensuring excellent service deliverability </li>
                                                    <li style={{ listStyleType: 'circle' }}>Setup thought provoking brainstorming session with engineering teams over “wicked problems”. </li>
                                                    <li style={{ listStyleType: 'circle' }}>Work with DevOps to make sure there are tools in place for monitoring the system, and log management to effectively handle incidents</li>
                                                    <li style={{ listStyleType: 'circle' }}>Perform research and share analysis on third party REST API integration in existing architecture Continuous strategizing to reduce complex backend workflow, API security and Log management for production support team. </li>
                                                    <li style={{ listStyleType: 'circle' }}>Adept at remote team management, owns my own team of 5 engineers and work with multiple engineering teams across the globe with their tech leads for achieving engineering goals. </li>
                                                    <li style={{ listStyleType: 'circle' }}>I lead, 20+ backend engineers at different level of their experience. Manage triage for functional defects and work closely with the development managers on resolution</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2016 - 2019</h2>
                                            <h3>BACKEND ENGINEER</h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    RRI
                                                </h4>
                                                <h5>San Diego, USA</h5>
                                                <ul className="description">
                                                    <li style={{ listStyleType: 'circle' }}>Awarded honor of “Platinum Team Player” in RRI engineering team</li>
                                                    <li style={{ listStyleType: 'circle' }}>Adept at technical discovery to identify the pain point in the system</li>
                                                    <li style={{ listStyleType: 'circle' }}>Delivered technical foundation for order processing by migrated legacy monolith in to Microservice with modern technology stack</li>
                                                    <li style={{ listStyleType: 'circle' }}>Deploy and manage microservice on Heroku platform</li>
                                                    <li style={{ listStyleType: 'circle' }}>Assisting POs and their technical team in understanding of system and providing support to their backend needs for their teams</li>
                                                    <li style={{ listStyleType: 'circle' }}>Design & Lead, Integration of DISC test, Timetrade, Clickfunnel Sales Page with Salesforce including Zapier, Zendesk</li>
                                                    <li style={{ listStyleType: 'circle' }}>Outline the plan & Lead, Migration of application server stacks from Heroku-16 to Heroku-18</li>
                                                    <li style={{ listStyleType: 'circle' }}>Set up custom API performance matrix for identifying the performance bottlenecks in the system</li>
                                                    <li style={{ listStyleType: 'circle' }}>Resolving technical queries for vendor’s engineering teams</li>
                                                    <li style={{ listStyleType: 'circle' }}>Identified and Automate manual process which saved 5+ hours per week for the accounting team</li>
                                                    <li style={{ listStyleType: 'circle' }}>Integrate registration endpoint in sales kiosk in Angular based POC</li>
                                                    <li style={{ listStyleType: 'circle' }}>Generated proofs of concept for AI bot which answers generic questions about the organization</li>
                                                    <li style={{ listStyleType: 'circle' }}>Setup AWS Quicksight Dashboard POC to showcase the pros n cons of the product</li>
                                                    <li style={{ listStyleType: 'circle' }}>Support event activities onsite - registration, packing learning material for event participants etc</li>
                                                    <li style={{ listStyleType: 'circle' }}>To support Microservice based development, established a seed project for api development</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2015 - 2016</h2>
                                            <h3>Technical Consultant</h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    SW
                                                </h4>
                                                <h5>San Diego, USA</h5>
                                                <ul className="description">
                                                    <li style={{ listStyleType: 'circle' }}>Developer for lead management system</li>
                                                    <li style={{ listStyleType: 'circle' }}>Provide suggestion for elevating the experience for sales rep. Grew profits by 20% showing active promos for sales reps based on service selection.</li>
                                                    <li style={{ listStyleType: 'circle' }}>Automate the manual information lookups which reduced sales call duration by 50% through automating information lookup</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2012 - 2014</h2>
                                            <h3>Team Lead</h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    Impelsys Pvt Ltd
                                                </h4>
                                                <h5>Bangalore, India</h5>
                                                <ul className="description">
                                                    <li style={{ listStyleType: 'circle' }}>Lead developer for setting up process for optimizing the processing of XML document</li>
                                                    <li style={{ listStyleType: 'circle' }}>Leading multiple teams for rolling out the product</li>
                                                    <li style={{ listStyleType: 'circle' }}>Manage expectation, Provide Clarity and set up a process for well-informed system for all teams</li>
                                                    <li style={{ listStyleType: 'circle' }}>Facilitated user acceptance testing and ensuring all concerns are tracked and reported to respective teams</li>
                                                    <li style={{ listStyleType: 'circle' }}>Develop project artifacts like SRS, User manuals etc.</li>
                                                    <li style={{ listStyleType: 'circle' }}>Conducting and supporting college campus interview and company's in-house interview drive</li>
                                                    <li style={{ listStyleType: 'circle' }}>Technical mentoring for team members</li>
                                                    <li style={{ listStyleType: 'circle' }}>Resolve technical issues among the developers</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2007 - 2012</h2>
                                            <h3>Sr Software Engineer </h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    Cybage
                                                </h4>
                                                <h5>Pune, India</h5>
                                                <ul className="description">
                                                    <li style={{ listStyleType: 'circle' }}> Visiting US & Spain, on-site for Project kickoff, training and technical discovery</li>
                                                    <li style={{ listStyleType: 'circle' }}> Act as configuration manager to adhere CMM level 5 process compliance in project artifacts</li>
                                                    <li style={{ listStyleType: 'circle' }}>Lead team for complete website redesign amd migration</li>
                                                    <li style={{ listStyleType: 'circle' }}> Lead engineer for integrating Akamai CDN in legacy in-house CMS</li>
                                                    <li style={{ listStyleType: 'circle' }}>Lead engineer for innovative project like connecting car mechanics with customer, DCT tree for self-diagnosis for car issues. Integral part of rearchitecting the legacy system to SOA based platform for their order management system along with vendor distribution</li>
                                                    <li style={{ listStyleType: 'circle' }}> Developer for Insurance lead management system and auto lead distribution based on defined business rules</li>
                                                    <li style={{ listStyleType: 'circle' }}>Code reviewer, Deployments, Production Support, Lead Patch Release and internal team communication</li>
                                                    <li style={{ listStyleType: 'circle' }}> Taking interview</li>
                                                    <li style={{ listStyleType: 'circle' }}> Provide Org level technical trainings, conduct PHP events for php resources</li>
                                                    <li style={{ listStyleType: 'circle' }}> Research and create POC for project requirements</li>
                                                    <li style={{ listStyleType: 'circle' }}> Develop process complaint project artifacts like SRS, FSR, WBS, User manuals etc</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2007</h2>
                                            <h3>Software Engineer</h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    Matrixsolution
                                                </h4>
                                                <h5>Mumbai, India</h5>
                                                <ul className="description">
                                                    <li style={{ listStyleType: 'circle' }}>Supporting development activity for rediff mobile WAP site</li>
                                                    <li style={{ listStyleType: 'circle' }}>Provide analysis of production issues along with solution and impact analysis of solution</li>
                                                    <li style={{ listStyleType: 'circle' }}>Acting associate in designing and optimizing the solution for mobile apps</li>
                                                    <li style={{ listStyleType: 'circle' }}>Improve user experience of website</li>
                                                    <li style={{ listStyleType: 'circle' }}>Testing WAP sites across mobile device like Nokia, Samsung, Motorola etc</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="datalist">
                                <div className="single-timeline-box fix">
                                    <div className="timeline-box">
                                        <div className="experience-time text-right">
                                            <h2>2005 - 2007</h2>
                                            <h3> Programmer Analyst</h3>
                                        </div>
                                    </div>
                                    <div className="timeline-box experiencedata">
                                        <div className="timeline">
                                            <div className="timeline-content">
                                                <h4 className="title">
                                                    <span><FontAwesomeIcon icon={faCircle} /></span>
                                                    Trselle Pvt Ltd
                                                </h4>
                                                <h5>Bangalore, India</h5>
                                                <ul className="description">
                                                    <li></li>
                                                    <li style={{ listStyleType: 'circle' }}> Custom plugin development for PHP based CMS</li>
                                                    <li style={{ listStyleType: 'circle' }}>Content Loading for CMS clients</li>
                                                    <li style={{ listStyleType: 'circle' }}> Ensure cross browser compatibility of the website</li>
                                                    <li style={{ listStyleType: 'circle' }}>Manual tester for website</li>
                                                    <li style={{ listStyleType: 'circle' }}>Perform research for better UI experience</li>
                                                    <li style={{ listStyleType: 'circle' }}>Client communication, Providing technical reasoning over the issues</li>
                                                    <li style={{ listStyleType: 'circle' }}>Manage customer's UI expectation</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
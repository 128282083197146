import React from "react";
import { slide as Menu } from 'react-burger-menu'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./Header.css";

function Header({ isMenuOpen, setIsMenuOpen }) {

    const handleMenuItemClick = () => {
        setIsMenuOpen(false);
    };
    const handleStateChange = (state) => {
        setIsMenuOpen(state.isOpen);
    };
    return (
        <>
            <header className="top-area desktop">
                <Navbar expand="lg" className="bg-body-tertiary custom-header">
                    <Container>
                        <Navbar.Brand href="#welcome-hero" className="d-flex align-items-center" style={{ color: ' #d63384' }}>
                            pritisolanki
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                            <Nav className="me-auto justify-content-end w-100">
                                <Nav.Link href="#skill">skills</Nav.Link>
                                <Nav.Link href="#education">education</Nav.Link>
                                <Nav.Link href="#experience">experience</Nav.Link>
                                <Nav.Link href="#profiles">Hackathons</Nav.Link>
                                <Nav.Link href="#demo">Demos</Nav.Link>
                                <Nav.Link href="#social">Presentation</Nav.Link>
                                <Nav.Link href="#social">social</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
            <Menu className="mobile" isOpen={isMenuOpen} onStateChange={(state) => handleStateChange(state)}>
                <a onClick={handleMenuItemClick} className="menu-item" href="#education">education</a>
                <a onClick={handleMenuItemClick} className="menu-item" href="#skill">skills</a>
                <a onClick={handleMenuItemClick} className="menu-item" href="#experience">experience</a>
                <a onClick={handleMenuItemClick} className="menu-item" href="#social">Social</a>
                <a onClick={handleMenuItemClick} className="menu-item" href="#profiles">Hackathons</a>
                <a onClick={handleMenuItemClick} className="menu-item" href="#slides">Presentation</a>
                <a onClick={handleMenuItemClick} className="menu-item" href="#demo">Demos</a>
            </Menu>
        </>
    );
}

export default Header;
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, fas } from '@fortawesome/free-solid-svg-icons'
import "./About.css"

library.add(fas, faTwitter, faLinkedinIn, faEnvelope)

export default function About() {
	return (
		<section id="about" className="about">
			<div className="section-heading text-center">
				<h2 style={{ color: '#3c096c' }}>about me</h2>
			</div>
			<div className="container">
				<div className="about-content">
					<div className="col-sm-6">
						<div className="single-about-txt">
							<h3>
							<b>AI-powered Tech Leader | 15 Years of Experience</b>
							</h3>
							<div>
							<ul class="list-group">
								<li class="list-group-item">I leverage my deep expertise in AI and machine learning to deliver innovative solutions that empower customer-facing and product teams.</li>
								<li class="list-group-item">Proven track record of building robust frameworks and product features that drive sustainable growth for startups.</li>
								<li class="list-group-item">Foster a collaborative environment where stakeholders, cross-functional teams, and vendors work together to requirement, technical triage, how to automate the business workflow for better outcomes</li>
								<li class="list-group-item">Implement automation to streamline processes, minimize downtime, and uncover customer pain points, ensuring continuous product improvement.</li>
								<li class="list-group-item">I am a strong advocate for diversity and inclusion, and I am passionate about mentoring and coaching junior team members to help them grow and succeed.</li>
							</ul>
							</div>
						</div>
					</div>
					<div className="col-sm-offset-1 col-sm-5 profile_image">
						<div className="single-about-img">
							<div className="about-list-icon">
								<ul>
									<li>
										<a href="https://twitter.com/pritisolanki" target="_blank" rel="noreferrer" aria-label="twitter">
											<FontAwesomeIcon icon={faTwitter} />
										</a>

									</li>
									<li>
										<a href="https://www.linkedin.com/in/pritisolanki/" target="_blank" rel="noreferrer" aria-label="linkedin">
											<FontAwesomeIcon icon={faLinkedinIn} />
										</a>
									</li>
									<li>
										<a href="mailto:pritijobs01@gmail.com?subject=From pritisolanki website" target="_blank" rel="noreferrer" aria-label="email">
											<FontAwesomeIcon icon={faEnvelope} />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}